import React, { ComponentProps, FC } from "react";

import "./styles.scss";

type LoadingIconTypes = {
  className?: string;
};

const LoadingIcon: FC<LoadingIconTypes> = ({
  className,
}): React.ReactElement => {
  return (
    <div className={`sk-chase ${className}`}>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  );
};

export default LoadingIcon;
