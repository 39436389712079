import React, { FC, useContext } from "react";
import { FormsContext, FormsContextProviderType } from "../FormsContext";

import { useQueryParam, NumberParam } from "use-query-params";

import "./styles.scss";

const FormHeader: FC = () => {
  const formContext: FormsContextProviderType = useContext(FormsContext);

  const formHeaderNames = [
    "Contact Information",
    "Session Details",
    "Confirmation",
  ];

  const currentPage = useQueryParam("page", NumberParam)[0];

  const barStyle = {
    width: currentPage * 100 + "%",
  };

  return (
    <>
      <div className="form-header">
        {formHeaderNames.map((item: string, index: number) => {
          const selected = currentPage >= index ? "selected" : "";
          return (
            <div
              key={index}
              className={`form-header--item ${selected}`}
              onClick={() => {
                if (selected && currentPage < formHeaderNames.length - 1)
                  formContext.setForm(index);
              }}
            >
              {index === 0 && (
                <div className="form-header--bar" style={barStyle}></div>
              )}

              <div className="form-header--item-number">
                {selected && <span>{index + 1}</span>}
              </div>
              <div className="form-header--label">{item}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default FormHeader;
