import React, { useEffect, useState, useContext } from "react";

import FormContainer from "../../components/Forms/FormContainer";
import { Page } from "../../components/Page";

import LoadingIcon from "../../components/Forms/LoadingIcon";
import confirmationCheck from "../../images/confirmation-check.svg";

import {
  FormsContext,
  FormsContextProviderType,
} from "../../components/Forms/FormsContext";

import { eventTracker, pageTracker } from "../../tracker";

const Confirmation = ({ location }) => {
  React.useEffect(() => {
    pageTracker({
      pageTitle: "Request KOL > Confirmation (Step 4/4)",
      pageSubject: "Meeting Request",
    });

    eventTracker({
      eventAction: "Viewed Step",
      eventCategory: "Request KOL Form",
      eventLabel: "Step 4/4 - Confirmation",
    });
  }, []);

  const [confirmed, setConfirmed] = useState(false);

  return (
    <Page>
      <FormContainer>
        {!confirmed && <LoadingPage onDoneLoading={() => setConfirmed(true)} />}
        {confirmed && <ConfirmationPage />}
      </FormContainer>
    </Page>
  );
};

const LoadingPage = ({ onDoneLoading }) => {
  const formContext: FormsContextProviderType = useContext(FormsContext);

  useEffect(() => {
    formContext
      .submit()
      .then(() => onDoneLoading())
      .catch((err) => console.log(err));
  });

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <LoadingIcon className="green large" />
    </div>
  );
};

const ConfirmationPage = () => {
  return (
    <div className="form-page--confirmation-page">
      <img className="form-page--confirmation-icon" src={confirmationCheck} />
      <h2 className="form-page--confirmation-headline">
        Thank you for requesting a session
      </h2>
      <p className="form-page--confirmation-content">
        We will reach out to you in 3-5 business days to confirm the details of
        your online session. Stay tuned!
      </p>
      <p className="form-page--confirmation-content">
        Please reach out to{" "}
        <a
          href="mailto:ExpertEducation@gbt.com"
          data-analytics-event-action="Followed"
          data-analytics-event-subject="Meeting Request"
          data-analytics-event-category="Contact Links"
          data-analytics-event-label="ExpertEducation@gbt.com"
        >
          ExpertEducation@gbt.com
        </a>{" "}
        for further information. In the meantime, you can learn more about
        Oxbytra:
      </p>
      <p className="form-page--confirmation-content">
        <a
          className="form-page--confirmation-content__link"
          href="https://hcp.oxbytra.com"
        >
          hcp.oxbytra.com
        </a>
      </p>
    </div>
  );
};

export default Confirmation;
